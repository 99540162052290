<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterProvince
                    class="mr-2"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
                <FilterKabupatenKota
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"
                    v-model:area="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <AMonthPicker
                    v-has-role="[ROLE_ADMIN_BK, ROLE_HELPDESK, ROLE_TSO, ROLE_ASM, ROLE_DISTRIBUTOR, ROLE_SPC, ROLE_SPC_GROUP]"
                    class="mr-2"
                    v-model:value="state.params.periode"
                    placeholder="Pilih Periode"
                    style="width:300px"
                    format="MMMM YYYY"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <ASelect
                    class="mr-2"
                    :options="state.statusMappingLists"
                    allow-clear
                    style="width: 300px;"
                    placeholder="Pilih Status Mapping Toko"
                    v-model:value="state.params.status_mapping"/>
                <!-- <AInputSearch
                    class="mr-2"
                    v-model:value="state.params.q"
                    placeholder="Cari..."
                    style="width:300px"/> -->
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end mt-4">
          <div class="col-lg-6 col-md-12"></div>
          <div class="col-lg-6 col-md-12 text-right">
            <a-button
                title="download excel"
                type="primary"
                @click="btnDownloadExcel"
                :loading="state.isDownloading">
                <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!state.isDownloading"></i>
                <span v-else>Downloading ...</span>
            </a-button>
          </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, watch } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import { pickBy } from 'lodash'
import {
    ROLE_ADMIN_BK,
    ROLE_HELPDESK,
    ROLE_ASM,
    ROLE_TSO,
    ROLE_DISTRIBUTOR,
    ROLE_SPC,
    ROLE_SPC_GROUP,
    hasRoles,
} from '@/helpers'
import moment from 'moment'

export default defineComponent({
    components: {
        FilterProvince,
        FilterKabupatenKota,
        FilterRegional,
        FilterArea,
        FilterToko,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },

                {
                    title: 'SSM',
                    dataIndex: 'user_ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'user_sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'user_am',
                },
                {
                    title: 'Status Mapping Toko',
                    dataIndex: 'status_mapping',
                },
            ],
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                region: [],
                provinsi: [],
                periode: moment(new Date()).startOf('month').format('YYYY-MM'),
                area: [],
                kabupaten: [],
                toko: [],
                status_mapping: null,
                page: 1,
                "per-page": 10,
            },
            statusMappingLists: [
                { value: 1, label: 'Sudah Termapping' },
                { value: 2, label: 'Belum Termapping' },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.periode) {
                params.periode = moment(state.params.periode).format('YYYY-MM')
            }

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get('/api/report-lelang/toko-lelang', {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    items.forEach((item, i) => {
                        item.key = i
                        item['progress_download'] = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // download export excel
        const btnDownloadExcel = () => {
            state.isDownloading = true
            apiClient
                .get('/api/report-lelang/toko-lelang', {
                    params: {
                        ...queryParams(),
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Laporan-Toko-Lelang_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.isDownloading = false
                })
        }

        const fetchXlsxMonth = (code) => {
            state.data.find(item => item.customer_code === code).progress_download = true
            apiClient
                .get('/api/report-lelang/toko-lelang', {
                    params: {
                        ...queryParams(),
                        customer_code: code,
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `Detail-Toko-Lelang-${code}_${moment().format('DDMMYY_HHmm')}.xlsx`,
                    )
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.data.find(item => item.customer_code === code).progress_download = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            // rowSelection,
            handleTableChange,
            btnDownloadExcel,
            errorMessage,
            fetchXlsxMonth,
            ROLE_ADMIN_BK,
            ROLE_HELPDESK,
            ROLE_ASM,
            ROLE_TSO,
            ROLE_DISTRIBUTOR,
            ROLE_SPC,
            ROLE_SPC_GROUP,
        }
    },
})
</script>
